import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Hero from './Hero';
import WhyUs from './WhyUs';
import OurTeam from './OurTeam';
import Footer from './Footer';
import SLevelTeaMenu from './SLevelTeaMenu';
import SwapnilJavanjalResume from './Swapnil-Javanjal-Resume';
import './App.css';

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={
            <>
              <Hero />
              <WhyUs />
              <OurTeam />
              <Footer />
            </>
          } />
          <Route path="/SLevelTeaMenu" element={<SLevelTeaMenu />} />
          <Route path="/Swapnil-Javanjal-Resume" element={<SwapnilJavanjalResume />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;