// OurTeam.js
import React from 'react';
import './OurTeam.css'; // Make sure to create an OurTeam.css file for styling
import teambackground from './images/ourteam-background.jpg';
import ContactUs from './ContactUs';

function OurTeam() {
  return (
    <section id="our-team" className="our-team" style={{ backgroundImage: `url(${teambackground})` }}>
      <div className="container">
        <h2>Powered by passion, our team works tirelessly, mirroring the ceaseless pulse of our city</h2>
        <div className="team-members">
          {/* Team Member */}
          <div className="team-member">
          <img src={require('./images/CEO_headshot.jpeg')} alt="Rajendra" className="team-member-headshot" />
            <h3>Rajendra</h3>
            <p>CEO</p>
            <p>Rajendra is a seasoned entrepreneur with a deep commitment to excellence. His background in finance and banking fuels our innovation and forward-thinking approach, inspiring both our team and partners</p>
          </div>
          <div className="team-member">
          <img src={require('./images/CTO_headshot.jpeg')} alt="Rajendra" className="team-member-headshot" />
            <h3>Swapnil</h3>
            <p>CTO</p>
            <p>Swapnil, our CTO, drives our startup's tech vision, focusing on ML/AI and product development. His commitment to innovation and excellence shapes our approach to solving complex challenges in the tech landscape</p>
          </div>
          <div className="team-member">
          <img src={require('./images/advisor.jpeg')} alt="Rajendra" className="team-member-headshot" />
            <h3>Kuan-Cheun</h3>
            <p>Advisor</p>
            <p>Kuan-Chuen Wu is an entrepreneur and AI specialist with a Harvard and Stanford Engineering background, leading Data Science teams in developing impactful AI solutions and educating over 6000 professionals to harness AI</p>
          </div>
        </div>
        <ContactUs />
      </div>
    </section>
  );
}

export default OurTeam;
