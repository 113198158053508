import React, { useState } from 'react';
import './ContactUs.css'; // Make sure you have a CSS file for styling

function ContactUs() {
  // State for form fields
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });

  // Handle form field changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
        ...prevState,
        [name]: value
    }));
  };
  
  const handleSubmit = (event) => {
    event.preventDefault();
      fetch(`https://sendgridemailappinnovaiva.azurewebsites.net/api/SendEmailFunction?`, {  
        method: 'POST',
        body: JSON.stringify(formData),
        headers: {
            'Content-Type': 'application/json'
        }
    })
    .then(response => {
        const contentType = response.headers.get('content-type');
        if (response.ok) {
            if (contentType && contentType.includes('application/json')) {
                return response.json(); // Process JSON data
            } else {
                return response.text(); // Process text data
            }
        }
        throw new Error('Network response was not ok. Status: ' + response.status);
    })
    .then(data => {
      console.log('Success:', data)
      setFormData({
        name: '',
        email: '',
        message: '',
      });
    })
    .catch(error => console.error('Error:', error));
};


  return (
    <section id="contact-us" className="contact-us">
      <div className="container">
        <h2>Contact Us</h2>
      <span class="contactus-underline"></span>
        <form id="contact-form" onSubmit={handleSubmit}>
          <input
            type="text"
            name="name"
            placeholder="Name"
            value={formData.name}
            onChange={handleChange}
            required
          />
          <input
            type="email"
            name="email"
            placeholder="Email"
            value={formData.email}
            onChange={handleChange}
            required
          />
          <textarea
            name="message"
            placeholder="Your Message"
            value={formData.message}
            onChange={handleChange}
            required
          ></textarea>
          <button type="submit">Send</button>
        </form>
      </div>
    </section>
  );
}

export default ContactUs;
