import React from 'react';
import './WhyUs.css';
import ideaIcon from './images/idea.gif';
import teamIcon from './images/team.gif';
import process from './images/process.gif';
import quality from './images/quality.gif';
import budget from './images/budget.gif';
import support from './images/techSupport.gif';

function WhyUs() {
  return (
    <section id="why-us" className="why-us">
    <div className="why-us-header">
      <h2>Discover the Advantages of Investing With Us</h2>
      <span class="heading-underline"></span>
      <p className="subtitle">Unlocking Wealth: Your Gateway to all Software Solutions</p>
      <div className="why-us-content">
        <div className="advantage-item">
          <img src={ideaIcon} alt="Innovative Solutions" />
          <h3>Innovative Solutions</h3>
          <p>Utilize cutting-edge technologies and innovative approaches to develop software solutions that drive efficiency and competitive advantage</p>
        </div>
        <div className="advantage-item">
          <img src={teamIcon} alt="Expert Team" />
          <h3>Expert Team</h3>
          <p>Collaborate with a team of skilled developers, UX/UI designers, and project managers who bring a wealth of experience and specialized expertise to your project</p>
        </div>
        <div className="advantage-item">
          <img src={process} alt="Transparent Processes" />
          <h3>Transparent Processes</h3>
          <p>Experience complete transparency with agile methodologies, allowing real-time tracking of project progress, adjustments, and open communication</p>
        </div>
        <div className="advantage-item">
          <img src={quality} alt="Excellence in Every Code" />
          <h3>Excellence in Every Code</h3>
          <p>Striving for perfection, we ensure every piece of software is a benchmark of quality. Our thorough testing protocols guarantee that you receive not just a product but the promise of reliability and excellence</p>
        </div>
        <div className="advantage-item">
          <img src={budget} alt="Budget Friendly" />
          <h3>Budget Friendly Customized Strategy</h3>
          <p>Receive Budget Friendly tailored software solutions designed to meet your specific business needs, ensuring that the final product aligns perfectly with your objectives</p>
        </div>
        <div className="advantage-item">
          <img src={support} alt="Robust Tech Support" />
          <h3>Robust Tech Support</h3>
          <p>Benefit from comprehensive post-launch support and a community of developers, ensuring your software continues to evolve and remain competitive</p>
        </div>
      </div>
    </div>
    </section>
  );
}

export default WhyUs;
