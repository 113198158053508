import React from 'react';
import './Hero.css'; // CSS file for styling the hero section
import logoImage from './images/InnovaIvaLabsLogo.png'; // The path to your logo image
import coverImage from './images/coverImage.jpg'; // The correct path to your cover image
import Header from './Header';

function Hero() {
  return (
    <div id="hero" className="hero" style={{ backgroundImage: `url(${coverImage})` }}>
        <Header />
        <div className="hero-content">
      <img src={logoImage} alt="Innovaiva Labs Logo" className="hero-logo" />
      <h1>Unlock Prosperity's Potential</h1>
      <p>Invest in your future and be part of the Artificial Intelligence revolution!</p>
      <a href="#contact-us" className="contact-us-btn">CONTACT US</a>
      </div>
    </div>
  );
}

export default Hero;
