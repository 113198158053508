import React from 'react';
import './Footer.css';
import InstagramIcon from './images/instagram.png';
import LinkedInIcon from './images/linkedin.png';
import FacebookIcon from './images/facebook.png';

function Footer() {
  return (
    <>
      <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.1/css/all.min.css" />
      <footer className="footer">
        <div className="footer-content">
          <p>Crafting Future Tech - <a href="mailto:innovaivalabs@gmail.com" className="email-link"><i class="fas fa-envelope"></i> Reach out to us!</a></p>
          <p>phone: +1 (201) 719-2006</p>
          <p>+91 9096333291</p>
          <p>+91 9960445176</p>
          <div className="footer-socials">
            <a href="https://www.instagram.com/innovaivalabs/" target="_blank" rel="noopener noreferrer">
              <img src={InstagramIcon} alt="Instagram" />
            </a>
            <a href="https://www.linkedin.com/company/innovaiva-labs/" target="_blank" rel="noopener noreferrer">
              <img src={LinkedInIcon} alt="LinkedIn" />
            </a>
            <a href="https://www.facebook.com/profile.php?id=61556782085479" target="_blank" rel="noopener noreferrer">
              <img src={FacebookIcon} alt="Facebook" />
            </a>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer;