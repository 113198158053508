// Header.js
import React, { useState } from 'react';
import './Header.css';
import hamburgerIcon from './images/hamburger.png';

function Header() {
  const [isNavExpanded, setIsNavExpanded] = useState(false);

  const toggleNav = () => {
    setIsNavExpanded((isNavExpanded) => !isNavExpanded);
  };  
  
  // Function to close the menu
  const closeMenu = () => {
    setIsNavExpanded(false);
  };

  return (
    <header className="header">
      {/* Hamburger icon that will be shown on mobile */}
      <img
        src={hamburgerIcon}
        className="hamburger"
        alt="Menu"
        onClick={toggleNav} // Attach the toggle function to the click event
      />
      {/* Conditionally apply a class to the nav element based on the state */}
      <nav className={`navbar ${isNavExpanded ? 'nav-active' : ''}`}>
        <a href="#hero" className="nav-link" onClick={closeMenu}>HOME</a>
        <a href="#why-us" className="nav-link" onClick={closeMenu}>WHY US</a>
        <a href="#our-team" className="nav-link" onClick={closeMenu}>OUR TEAM</a>
        <a href="#contact-us" className="nav-link" onClick={closeMenu}>CONTACT US</a>
      </nav>
    </header>
  );
}

export default Header;
