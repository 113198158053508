// src/Swapnil-Javanjal-Resume.js
import React from 'react';
import SwapnilJavanjalResume from './images/Swapnil-Javanjal-Resume.pdf';

function SwapnilResume() {
  return (
    <div className="resume-container" style={{ height: "100vh", width: "100%" }}>
      <object
        data={SwapnilJavanjalResume}
        type="application/pdf"
        width="100%"
        height="100%"
      >
        <p>Your browser does not support PDFs. Please download the PDF to view it: 
          <a href={SwapnilJavanjalResume}>Download PDF</a>.
        </p>
      </object>
    </div>
  );
}

export default SwapnilResume;
