import React from 'react';
import './SLevelTeaMenu.css'; // Ensure you have a CSS file for styling

// Import images from your images directory
import Example3 from './images/SLevelTea/Example_3.png';

function SLevelTeaMenu() {
    // Structured data for menu items
    const menuItems = [
        { id: 1, name: "Panda Milk Tea", description: "Black milk tea + tapioca + panda syrup", price: "6.50", image: Example3 },
        { id: 2, name: "Mountain Milk Tea", description: "Oolong milk tea + grass jelly and aloe vera", price: "6.50", image: Example3 },
        { id: 3, name: "Oolong Milk Tea with Grass Jelly", description: "Oolong milk tea + grass jelly", price: "6.25", image: Example3 },
        { id: 4, name: "Flamingo Yogurt Drink", description: "Yogurt + strawberry + mango and strawberry poppers", price: "6.25", image: Example3 },
        { id: 5, name: "Combination Frozen Yogurt", description: "Yogurt + mango and strawberry", price: "6.50", image: Example3 },
        { id: 6, name: "Salted Cheese Green Tea", description: "Green tea + salted cheese", price: "5.50", image: Example3 },
        { id: 7, name: "Kumquat Lemon Ice Tea with Lychee", description: "Kumquat lemon ice tea + lychee", price: "6.50", image: Example3 },
        { id: 8, name: "Fresh Avocado Smoothie", description: "Avocado smoothie", price: "6.50", image: Example3 },
        { id: 9, name: "Vietnamese Coffee Smoothie", description: "Vietnamese coffee smoothie", price: "6.00", image: Example3 },
        { id: 10, name: "Thai Milk Tea", description: "Thai green milk tea", price: "6.50", image: Example3 },
        { id: 11, name: "Kawaii Slushy", description: "Strawberry + peach + passion fruit + rainbow jelly", price: "6.50", image: Example3 },
        { id: 12, name: "Honey Lemon Black Ice Tea", description: "Honey lemon black ice tea", price: "6.00", image: Example3 },
        { id: 13, name: "Matcha Latte", description: "Matcha latte", price: "5.50", image: Example3 },
        { id: 14, name: "Fresh Strawberry Banana Smoothie", description: "Strawberry banana smoothie", price: "6.25", image: Example3 },
        { id: 15, name: "Oreo Milk Tea", description: "Black milk tea + Oreo + salted cheese", price: "6.25", image: Example3 },
        { id: 16, name: "Strawberry Forest Milk Tea", description: "Strawberry milk tea + rainbow", price: "6.25", image: Example3 },
        { id: 17, name: "Brown Sugar Fresh Milk with Tapioca", description: "Brown sugar fresh milk + tapioca", price: "6.25", image: Example3 },
        { id: 18, name: "Pink Summer Slushy", description: "Peach + grape", price: "6.00", image: Example3 },
        { id: 19, name: "Green Summer Slushy", description: "Kiwi + apple + kiwi poppers", price: "6.00", image: Example3 },
        { id: 20, name: "Strawberry Lemonade", description: "Strawberry lemonade", price: "5.50", image: Example3 },
        { id: 21, name: "Taro Milk Tea with Tapioca", description: "Taro milk tea + tapioca", price: "6.25", image: Example3 },
        { id: 22, name: "Trust Me Tareo Smoothie", description: "Taro smoothie + cream", price: "6.50", image: Example3 },
        { id: 23, name: "S Level Coffee", description: "Vietnamese coffee + salted cheese", price: "6.25", image: Example3 },
        { id: 24, name: "House Coffee", description: "Coffee", price: "6.25", image: Example3 },
    ];

    return (
        <div className="menu-container">
            <h1 className="menu-title">S Level Tea Menu</h1>
            {menuItems.map(item => (
                <div key={item.id} className="menu-item-row">
                    <div className="menu-item-image-container">
                        <img src={item.image} alt={item.name} className="menu-item-image" />
                    </div>
                    <div className="menu-item-details">
                        <h3 className="menu-item-name">{item.name}</h3>
                        <p className="menu-item-description">{item.description}</p>
                        <span className="menu-item-price">${item.price}</span>
                    </div>
                </div>
            ))}
        </div>
    );
}

export default SLevelTeaMenu;